var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        width: 700,
        visible: _vm.visible,
        forceRender: "",
        confirmLoading: _vm.confirmLoading,
        cancelText: "关闭",
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.confirmLoading } },
        [
          _c(
            "a-form",
            [
              _c(
                "a-row",
                { staticClass: "form-row", attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "序号",
                          },
                        },
                        [
                          _c("a-input-number", {
                            attrs: { min: 0 },
                            model: {
                              value: _vm.formData.sort,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "sort", $$v)
                              },
                              expression: "formData.sort",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { staticClass: "form-row", attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "应用场景",
                          },
                        },
                        [
                          _c("a-select", {
                            attrs: {
                              options: _vm.sceneOptions,
                              placeholder: "请选择应用场景",
                            },
                            on: { change: _vm.selectChange },
                            model: {
                              value: _vm.formData.scene,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "scene", $$v)
                              },
                              expression: "formData.scene",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { staticClass: "form-row", attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "发送方式",
                          },
                        },
                        [
                          _c("a-checkbox-group", {
                            attrs: { options: _vm.sendTypeOptions },
                            model: {
                              value: _vm.formData.sendTypes,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "sendTypes", $$v)
                              },
                              expression: "formData.sendTypes",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { staticClass: "form-row", attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "优先级",
                          },
                        },
                        [
                          _c("a-radio-group", {
                            attrs: { options: _vm.options },
                            model: {
                              value: _vm.formData.priority,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "priority", $$v)
                              },
                              expression: "formData.priority",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.visible
                ? _c(
                    "a-row",
                    {
                      staticClass: "form-row template-name",
                      attrs: { gutter: 24 },
                    },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                labelCol: _vm.labelCol,
                                wrapperCol: _vm.wrapperCol,
                                label: "主题",
                              },
                            },
                            [
                              _vm.formData.scene
                                ? _c(
                                    "div",
                                    { staticClass: "tag-list" },
                                    [
                                      _vm.formData.scene != "WELCOME" &&
                                      _vm.formData.scene !=
                                        "SETTLE_BOSS_CONFIRMED" &&
                                      _vm.formData.scene !=
                                        "SETTLE_BOSS_PAYED" &&
                                      _vm.formData.scene != "GRADE"
                                        ? _c(
                                            "a-tag",
                                            {
                                              ref: "productName",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addTagName(
                                                    "productName"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("作品名称")]
                                          )
                                        : _vm._e(),
                                      _vm.formData.scene != "WELCOME" &&
                                      _vm.formData.scene !=
                                        "SETTLE_BOSS_CONFIRMED" &&
                                      _vm.formData.scene !=
                                        "SETTLE_BOSS_PAYED" &&
                                      _vm.formData.scene != "GRADE"
                                        ? _c(
                                            "a-tag",
                                            {
                                              ref: "chapterOrder",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addTagName(
                                                    "chapterOrder"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("章节序号")]
                                          )
                                        : _vm._e(),
                                      _vm.formData.scene != "WELCOME" &&
                                      _vm.formData.scene !=
                                        "SETTLE_BOSS_CONFIRMED" &&
                                      _vm.formData.scene !=
                                        "SETTLE_BOSS_PAYED" &&
                                      _vm.formData.scene != "GRADE"
                                        ? _c(
                                            "a-tag",
                                            {
                                              ref: "chapterName",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addTagName(
                                                    "chapterName"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("章节名称")]
                                          )
                                        : _vm._e(),
                                      _vm.formData.scene != "WELCOME" &&
                                      _vm.formData.scene !=
                                        "SETTLE_BOSS_CONFIRMED" &&
                                      _vm.formData.scene !=
                                        "SETTLE_BOSS_PAYED" &&
                                      _vm.formData.scene != "GRADE"
                                        ? _c(
                                            "a-tag",
                                            {
                                              ref: "taskName",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addTagName(
                                                    "taskName"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("阶段名称")]
                                          )
                                        : _vm._e(),
                                      _vm.formData.scene != "WELCOME" &&
                                      _vm.formData.scene !=
                                        "SETTLE_BOSS_CONFIRMED" &&
                                      _vm.formData.scene !=
                                        "SETTLE_BOSS_PAYED" &&
                                      _vm.formData.scene != "GRADE"
                                        ? _c(
                                            "a-tag",
                                            {
                                              ref: "rejectStage",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addTagName(
                                                    "rejectStage"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("驳回阶段名称")]
                                          )
                                        : _vm._e(),
                                      _vm.formData.scene != "WELCOME" &&
                                      _vm.formData.scene !=
                                        "SETTLE_BOSS_CONFIRMED" &&
                                      _vm.formData.scene !=
                                        "SETTLE_BOSS_PAYED" &&
                                      _vm.formData.scene != "GRADE"
                                        ? _c(
                                            "a-tag",
                                            {
                                              ref: "limitDate",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addTagName(
                                                    "limitDate"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("截止日期")]
                                          )
                                        : _vm._e(),
                                      _vm.formData.scene != "WELCOME" &&
                                      _vm.formData.scene !=
                                        "SETTLE_BOSS_CONFIRMED" &&
                                      _vm.formData.scene !=
                                        "SETTLE_BOSS_PAYED" &&
                                      _vm.formData.scene != "TASK_REARRANGE" &&
                                      _vm.formData.scene != "GRADE"
                                        ? _c(
                                            "a-tag",
                                            {
                                              ref: "deadline",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addTagName(
                                                    "deadline"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("截止时间")]
                                          )
                                        : _vm._e(),
                                      _vm.formData.scene != "WELCOME" &&
                                      _vm.formData.scene !=
                                        "SETTLE_BOSS_CONFIRMED" &&
                                      _vm.formData.scene != "SETTLE_BOSS_PAYED"
                                        ? _c(
                                            "a-tag",
                                            {
                                              ref: "member",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addTagName(
                                                    "member"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("人员")]
                                          )
                                        : _vm._e(),
                                      _vm.formData.scene ==
                                        "SETTLE_BOSS_CONFIRMED" ||
                                      _vm.formData.scene == "SETTLE_BOSS_PAYED"
                                        ? _c(
                                            "a-tag",
                                            {
                                              ref: "department",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addTagName(
                                                    "department"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("部门")]
                                          )
                                        : _vm._e(),
                                      _vm.formData.scene == "GRADE" ||
                                      _vm.formData.scene ==
                                        "SETTLE_BOSS_CONFIRMED" ||
                                      _vm.formData.scene == "SETTLE_BOSS_PAYED"
                                        ? _c(
                                            "a-tag",
                                            {
                                              ref: "ratingDate",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addTagName(
                                                    "ratingDate"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("日期（年-月）")]
                                          )
                                        : _vm._e(),
                                      _vm.formData.scene == "GRADE"
                                        ? _c(
                                            "a-tag",
                                            {
                                              ref: "position",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addTagName(
                                                    "position"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("职位")]
                                          )
                                        : _vm._e(),
                                      _vm.formData.scene == "TASK_REARRANGE"
                                        ? _c(
                                            "a-tag",
                                            {
                                              ref: "delayReason",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addTagName(
                                                    "delayReason"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("原因")]
                                          )
                                        : _vm._e(),
                                      _vm.formData.scene == "WELCOME" ||
                                      _vm.formData.scene ==
                                        "SETTLE_BOSS_CONFIRMED" ||
                                      _vm.formData.scene == "SETTLE_BOSS_PAYED"
                                        ? _c(
                                            "a-tag",
                                            {
                                              ref: "nickName",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addTagName(
                                                    "nickName"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("昵称")]
                                          )
                                        : _vm._e(),
                                      _vm.formData.scene == "WELCOME"
                                        ? _c(
                                            "a-tag",
                                            {
                                              ref: "email",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addTagName("email")
                                                },
                                              },
                                            },
                                            [_vm._v("邮箱")]
                                          )
                                        : _vm._e(),
                                      _vm.formData.scene == "WELCOME"
                                        ? _c(
                                            "a-tag",
                                            {
                                              ref: "password",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addTagName(
                                                    "password"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("密码")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("j-editor", {
                                style: _vm.confirmLoading
                                  ? "opacity: 0;"
                                  : "opacity: 1;",
                                on: { onClick: _vm.onTitleClick },
                                model: {
                                  value: _vm.templateName,
                                  callback: function ($$v) {
                                    _vm.templateName = $$v
                                  },
                                  expression: "templateName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.visible
                ? _c(
                    "a-row",
                    {
                      staticClass: "form-row template-editor",
                      attrs: { gutter: 24 },
                    },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                labelCol: _vm.labelCol,
                                wrapperCol: _vm.wrapperCol,
                                label: "模板内容",
                              },
                            },
                            [
                              _vm.formData.scene
                                ? _c(
                                    "div",
                                    { staticClass: "tag-list" },
                                    [
                                      _vm.formData.scene != "WELCOME" &&
                                      _vm.formData.scene !=
                                        "SETTLE_BOSS_CONFIRMED" &&
                                      _vm.formData.scene !=
                                        "SETTLE_BOSS_PAYED" &&
                                      _vm.formData.scene != "GRADE"
                                        ? _c(
                                            "a-tag",
                                            {
                                              ref: "productName",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addTag(
                                                    "productName"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("作品名称")]
                                          )
                                        : _vm._e(),
                                      _vm.formData.scene != "WELCOME" &&
                                      _vm.formData.scene !=
                                        "SETTLE_BOSS_CONFIRMED" &&
                                      _vm.formData.scene !=
                                        "SETTLE_BOSS_PAYED" &&
                                      _vm.formData.scene != "GRADE"
                                        ? _c(
                                            "a-tag",
                                            {
                                              ref: "chapterOrder",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addTag(
                                                    "chapterOrder"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("章节序号")]
                                          )
                                        : _vm._e(),
                                      _vm.formData.scene != "WELCOME" &&
                                      _vm.formData.scene !=
                                        "SETTLE_BOSS_CONFIRMED" &&
                                      _vm.formData.scene !=
                                        "SETTLE_BOSS_PAYED" &&
                                      _vm.formData.scene != "GRADE"
                                        ? _c(
                                            "a-tag",
                                            {
                                              ref: "chapterName",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addTag(
                                                    "chapterName"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("章节名称")]
                                          )
                                        : _vm._e(),
                                      _vm.formData.scene != "WELCOME" &&
                                      _vm.formData.scene !=
                                        "SETTLE_BOSS_CONFIRMED" &&
                                      _vm.formData.scene !=
                                        "SETTLE_BOSS_PAYED" &&
                                      _vm.formData.scene != "GRADE"
                                        ? _c(
                                            "a-tag",
                                            {
                                              ref: "taskName",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addTag("taskName")
                                                },
                                              },
                                            },
                                            [_vm._v("阶段名称")]
                                          )
                                        : _vm._e(),
                                      _vm.formData.scene != "WELCOME" &&
                                      _vm.formData.scene !=
                                        "SETTLE_BOSS_CONFIRMED" &&
                                      _vm.formData.scene !=
                                        "SETTLE_BOSS_PAYED" &&
                                      _vm.formData.scene != "GRADE"
                                        ? _c(
                                            "a-tag",
                                            {
                                              ref: "rejectStage",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addTag(
                                                    "rejectStage"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("驳回阶段名称")]
                                          )
                                        : _vm._e(),
                                      _vm.formData.scene != "WELCOME" &&
                                      _vm.formData.scene !=
                                        "SETTLE_BOSS_CONFIRMED" &&
                                      _vm.formData.scene !=
                                        "SETTLE_BOSS_PAYED" &&
                                      _vm.formData.scene != "GRADE"
                                        ? _c(
                                            "a-tag",
                                            {
                                              ref: "limitDate",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addTag("limitDate")
                                                },
                                              },
                                            },
                                            [_vm._v("截止日期")]
                                          )
                                        : _vm._e(),
                                      _vm.formData.scene != "WELCOME" &&
                                      _vm.formData.scene !=
                                        "SETTLE_BOSS_CONFIRMED" &&
                                      _vm.formData.scene !=
                                        "SETTLE_BOSS_PAYED" &&
                                      _vm.formData.scene != "TASK_REARRANGE" &&
                                      _vm.formData.scene != "GRADE"
                                        ? _c(
                                            "a-tag",
                                            {
                                              ref: "deadline",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addTag("deadline")
                                                },
                                              },
                                            },
                                            [_vm._v("截止时间")]
                                          )
                                        : _vm._e(),
                                      _vm.formData.scene != "WELCOME" &&
                                      _vm.formData.scene !=
                                        "SETTLE_BOSS_CONFIRMED" &&
                                      _vm.formData.scene != "SETTLE_BOSS_PAYED"
                                        ? _c(
                                            "a-tag",
                                            {
                                              ref: "member",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addTag("member")
                                                },
                                              },
                                            },
                                            [_vm._v("人员")]
                                          )
                                        : _vm._e(),
                                      _vm.formData.scene ==
                                        "SETTLE_BOSS_CONFIRMED" ||
                                      _vm.formData.scene == "SETTLE_BOSS_PAYED"
                                        ? _c(
                                            "a-tag",
                                            {
                                              ref: "department",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addTag(
                                                    "department"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("部门")]
                                          )
                                        : _vm._e(),
                                      _vm.formData.scene == "GRADE" ||
                                      _vm.formData.scene ==
                                        "SETTLE_BOSS_CONFIRMED" ||
                                      _vm.formData.scene == "SETTLE_BOSS_PAYED"
                                        ? _c(
                                            "a-tag",
                                            {
                                              ref: "ratingDate",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addTag(
                                                    "ratingDate"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("日期（年-月）")]
                                          )
                                        : _vm._e(),
                                      _vm.formData.scene == "GRADE"
                                        ? _c(
                                            "a-tag",
                                            {
                                              ref: "position",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addTag("position")
                                                },
                                              },
                                            },
                                            [_vm._v("职位")]
                                          )
                                        : _vm._e(),
                                      _vm.formData.scene == "TASK_REARRANGE"
                                        ? _c(
                                            "a-tag",
                                            {
                                              ref: "delayReason",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addTag(
                                                    "delayReason"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("原因")]
                                          )
                                        : _vm._e(),
                                      _vm.formData.scene == "WELCOME" ||
                                      _vm.formData.scene ==
                                        "SETTLE_BOSS_CONFIRMED" ||
                                      _vm.formData.scene == "SETTLE_BOSS_PAYED"
                                        ? _c(
                                            "a-tag",
                                            {
                                              ref: "nickName",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addTag("nickName")
                                                },
                                              },
                                            },
                                            [_vm._v("昵称")]
                                          )
                                        : _vm._e(),
                                      _vm.formData.scene == "WELCOME"
                                        ? _c(
                                            "a-tag",
                                            {
                                              ref: "email",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addTag("email")
                                                },
                                              },
                                            },
                                            [_vm._v("邮箱")]
                                          )
                                        : _vm._e(),
                                      _vm.formData.scene == "WELCOME"
                                        ? _c(
                                            "a-tag",
                                            {
                                              ref: "password",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addTag("password")
                                                },
                                              },
                                            },
                                            [_vm._v("密码")]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "span",
                                        {
                                          ref: "start",
                                          staticClass: "triangle_border_right",
                                          on: {
                                            click: function ($event) {
                                              return _vm.addTag("start")
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              style:
                                                "border-left-color:" +
                                                _vm.fontColor,
                                            },
                                            [_vm._v("startIcon")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          ref: "end",
                                          staticClass: "end-icon",
                                          style:
                                            "background-color:" + _vm.fontColor,
                                          on: {
                                            click: function ($event) {
                                              return _vm.addTag("end")
                                            },
                                          },
                                        },
                                        [_c("span", [_vm._v("endIcon")])]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("j-editor", {
                                ref: "editor",
                                style: _vm.confirmLoading
                                  ? "opacity: 0;"
                                  : "opacity: 1;",
                                on: { onClick: _vm.onEditorClick },
                                model: {
                                  value: _vm.templateEditorContent,
                                  callback: function ($$v) {
                                    _vm.templateEditorContent = $$v
                                  },
                                  expression: "templateEditorContent",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }