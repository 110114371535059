<template>
  <a-modal
    :title="title"
    :width="700"
    :visible="visible"
    forceRender
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭"
  >
    <a-spin :spinning="confirmLoading">
      <a-form>
        <!--         <a-row :gutter="{ xs: 8, sm: 16, md: 24, lg: 32 }">
          <a-col :span="24">
            <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="模板类型">
              <j-dict-select-tag
                @change="handleChangeTemplateType"
                :triggerChange="true"
                dictCode="msgType"
                v-decorator="['templateType', validatorRules.templateType]"
                placeholder="请选择模板类型"
              ></j-dict-select-tag>
            </a-form-item>
          </a-col>
        </a-row> -->
        <!--         <a-row :gutter="{ xs: 8, sm: 16, md: 24, lg: 32 }">
          <a-col :span="24">
            <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="模板CODE">
              <a-input
                :disabled="disable"
                placeholder="请输入模板编码"
                v-decorator="['templateCode', validatorRules.templateCode]"
              />
            </a-form-item>
          </a-col>
        </a-row>-->
        <!--         <a-row class="form-row" :gutter="24">
          <a-col :span="24">
            <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="模板名称">
              <a-input
                placeholder="请输入模板名称"
                v-decorator="['templateName', validatorRules.templateName]"
              />
            </a-form-item>
          </a-col>
        </a-row> -->
        <a-row class="form-row" :gutter="24">
          <a-col :span="24">
            <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="序号">
              <!-- <a-input placeholder="请选择应用场景" v-decorator="['templateName', validatorRules.templateName]" /> -->
              <a-input-number v-model="formData.sort" :min="0" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row class="form-row" :gutter="24">
          <a-col :span="24">
            <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="应用场景">
              <!-- <a-input placeholder="请选择应用场景" v-decorator="['templateName', validatorRules.templateName]" /> -->
              <a-select
                v-model="formData.scene"
                @change="selectChange"
                :options="sceneOptions"
                placeholder="请选择应用场景"
              ></a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row class="form-row" :gutter="24">
          <a-col :span="24">
            <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="发送方式">
              <a-checkbox-group v-model="formData.sendTypes" :options="sendTypeOptions" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row class="form-row" :gutter="24">
          <a-col :span="24">
            <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="优先级">
              <a-radio-group v-model="formData.priority" :options="options" />
            </a-form-item>
          </a-col>
        </a-row>
        <!--         <a-row class="form-row" :gutter="24">
          <a-col :span="24">
            <a-form-item v-show="!useEditor" :labelCol="labelCol" :wrapperCol="wrapperCol" label="模板内容">
              <a-textarea placeholder="请输入模板内容" :autosize="{ minRows: 8, maxRows: 8 }" />
            </a-form-item>
          </a-col>
        </a-row> -->
        <a-row v-if="visible" class="form-row template-name" :gutter="24">
          <a-col :span="24">
            <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="主题">
              <div v-if="formData.scene" class="tag-list">
                <a-tag
                  v-if="formData.scene != 'WELCOME'&&formData.scene != 'SETTLE_BOSS_CONFIRMED'&&formData.scene != 'SETTLE_BOSS_PAYED' && formData.scene != 'GRADE'"
                  @click="addTagName('productName')"
                  ref="productName"
                  >作品名称</a-tag
                >
                <a-tag
                  v-if="formData.scene != 'WELCOME'&&formData.scene != 'SETTLE_BOSS_CONFIRMED'&&formData.scene != 'SETTLE_BOSS_PAYED' && formData.scene != 'GRADE'"
                  @click="addTagName('chapterOrder')"
                  ref="chapterOrder"
                  >章节序号</a-tag
                >
                <a-tag
                  v-if="formData.scene != 'WELCOME'&&formData.scene != 'SETTLE_BOSS_CONFIRMED'&&formData.scene != 'SETTLE_BOSS_PAYED' && formData.scene != 'GRADE'"
                  @click="addTagName('chapterName')"
                  ref="chapterName"
                  >章节名称</a-tag
                >
                <a-tag
                  v-if="formData.scene != 'WELCOME'&&formData.scene != 'SETTLE_BOSS_CONFIRMED'&&formData.scene != 'SETTLE_BOSS_PAYED' && formData.scene != 'GRADE'"
                  @click="addTagName('taskName')"
                  ref="taskName"
                  >阶段名称</a-tag
                >
                <a-tag
                  v-if="formData.scene != 'WELCOME'&&formData.scene != 'SETTLE_BOSS_CONFIRMED'&&formData.scene != 'SETTLE_BOSS_PAYED' && formData.scene != 'GRADE'"
                  @click="addTagName('rejectStage')"
                  ref="rejectStage"
                  >驳回阶段名称</a-tag
                >
                <!-- <a-tag @click="addTag('task-tag')" ref="task-tag">任务名称</a-tag> -->
                <a-tag
                  v-if="formData.scene != 'WELCOME'&&formData.scene != 'SETTLE_BOSS_CONFIRMED'&&formData.scene != 'SETTLE_BOSS_PAYED' && formData.scene != 'GRADE'"
                  @click="addTagName('limitDate')"
                  ref="limitDate"
                  >截止日期</a-tag
                >
                <a-tag
                  v-if="formData.scene != 'WELCOME'&&formData.scene != 'SETTLE_BOSS_CONFIRMED'&&formData.scene != 'SETTLE_BOSS_PAYED' && formData.scene != 'TASK_REARRANGE' && formData.scene != 'GRADE'"
                  @click="addTagName('deadline')"
                  ref="deadline"
                  >截止时间</a-tag
                >
                <a-tag v-if="formData.scene != 'WELCOME'&&formData.scene != 'SETTLE_BOSS_CONFIRMED'&&formData.scene != 'SETTLE_BOSS_PAYED'" @click="addTagName('member')" ref="member">人员</a-tag>
                <a-tag v-if="formData.scene == 'SETTLE_BOSS_CONFIRMED'||formData.scene == 'SETTLE_BOSS_PAYED' " @click="addTagName('department')" ref="department"
                  >部门</a-tag
                >
                <a-tag v-if="formData.scene == 'GRADE'||formData.scene == 'SETTLE_BOSS_CONFIRMED'||formData.scene == 'SETTLE_BOSS_PAYED' " @click="addTagName('ratingDate')" ref="ratingDate"
                  >日期（年-月）</a-tag
                >
                <a-tag v-if="formData.scene == 'GRADE'" @click="addTagName('position')" ref="position">职位</a-tag>
                <a-tag v-if="formData.scene == 'TASK_REARRANGE'" @click="addTagName('delayReason')" ref="delayReason"
                  >原因</a-tag
                >
                <!-- <a-tag @click="addTag('post-tag')" ref="post-tag">发件人</a-tag> -->
                <a-tag v-if="formData.scene == 'WELCOME'||formData.scene == 'SETTLE_BOSS_CONFIRMED'||formData.scene == 'SETTLE_BOSS_PAYED' " @click="addTagName('nickName')" ref="nickName">昵称</a-tag>
                <a-tag v-if="formData.scene == 'WELCOME'" @click="addTagName('email')" ref="email">邮箱</a-tag>
                <a-tag v-if="formData.scene == 'WELCOME'" @click="addTagName('password')" ref="password">密码</a-tag>
                <!--                 <span class="triangle_border_right" ref="start" @click="addTagName('start')">
                  <span :style="'border-left-color:' + fontColor">startIcon</span>
                </span> -->
                <!-- <a-tag @click="addTagName('ip-tag')" ref="ip-tag">IP</a-tag> -->
              </div>
              <j-editor
                :style="confirmLoading ? 'opacity: 0;' : 'opacity: 1;'"
                @onClick="onTitleClick"
                v-model="templateName"
              ></j-editor>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row v-if="visible" class="form-row template-editor" :gutter="24">
          <a-col :span="24">
            <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="模板内容">
              <div v-if="formData.scene" class="tag-list">
                <a-tag
                  v-if="formData.scene != 'WELCOME'&&formData.scene != 'SETTLE_BOSS_CONFIRMED'&&formData.scene != 'SETTLE_BOSS_PAYED' && formData.scene != 'GRADE'"
                  @click="addTag('productName')"
                  ref="productName"
                  >作品名称</a-tag
                >
                <a-tag
                  v-if="formData.scene != 'WELCOME'&&formData.scene != 'SETTLE_BOSS_CONFIRMED'&&formData.scene != 'SETTLE_BOSS_PAYED' && formData.scene != 'GRADE'"
                  @click="addTag('chapterOrder')"
                  ref="chapterOrder"
                  >章节序号</a-tag
                >
                <a-tag
                  v-if="formData.scene != 'WELCOME'&&formData.scene != 'SETTLE_BOSS_CONFIRMED'&&formData.scene != 'SETTLE_BOSS_PAYED' && formData.scene != 'GRADE'"
                  @click="addTag('chapterName')"
                  ref="chapterName"
                  >章节名称</a-tag
                >
                <a-tag
                  v-if="formData.scene != 'WELCOME'&&formData.scene != 'SETTLE_BOSS_CONFIRMED'&&formData.scene != 'SETTLE_BOSS_PAYED' && formData.scene != 'GRADE'"
                  @click="addTag('taskName')"
                  ref="taskName"
                  >阶段名称</a-tag
                >
                <a-tag
                  v-if="formData.scene != 'WELCOME'&&formData.scene != 'SETTLE_BOSS_CONFIRMED'&&formData.scene != 'SETTLE_BOSS_PAYED' && formData.scene != 'GRADE'"
                  @click="addTag('rejectStage')"
                  ref="rejectStage"
                  >驳回阶段名称</a-tag
                >
                <!-- <a-tag @click="addTag('task-tag')" ref="task-tag">任务名称</a-tag> -->
                <a-tag
                  v-if="formData.scene != 'WELCOME'&&formData.scene != 'SETTLE_BOSS_CONFIRMED'&&formData.scene != 'SETTLE_BOSS_PAYED' && formData.scene != 'GRADE'"
                  @click="addTag('limitDate')"
                  ref="limitDate"
                  >截止日期</a-tag
                >
                <a-tag
                  v-if="formData.scene != 'WELCOME'&&formData.scene != 'SETTLE_BOSS_CONFIRMED'&&formData.scene != 'SETTLE_BOSS_PAYED' && formData.scene != 'TASK_REARRANGE' && formData.scene != 'GRADE'"
                  @click="addTag('deadline')"
                  ref="deadline"
                  >截止时间</a-tag
                >
                <a-tag v-if="formData.scene != 'WELCOME'&&formData.scene != 'SETTLE_BOSS_CONFIRMED'&&formData.scene != 'SETTLE_BOSS_PAYED'" @click="addTag('member')" ref="member">人员</a-tag>
                <a-tag v-if="formData.scene == 'SETTLE_BOSS_CONFIRMED'||formData.scene == 'SETTLE_BOSS_PAYED' " @click="addTag('department')" ref="department">部门</a-tag>
                <a-tag v-if="formData.scene == 'GRADE'||formData.scene == 'SETTLE_BOSS_CONFIRMED'||formData.scene == 'SETTLE_BOSS_PAYED' " @click="addTag('ratingDate')" ref="ratingDate">日期（年-月）</a-tag>
                <a-tag v-if="formData.scene == 'GRADE'" @click="addTag('position')" ref="position">职位</a-tag>
                <a-tag v-if="formData.scene == 'TASK_REARRANGE'" @click="addTag('delayReason')" ref="delayReason"
                  >原因</a-tag
                >
                <!-- <a-tag @click="addTag('post-tag')" ref="post-tag">发件人</a-tag> -->
                <a-tag v-if="formData.scene == 'WELCOME'||formData.scene == 'SETTLE_BOSS_CONFIRMED'||formData.scene == 'SETTLE_BOSS_PAYED' " @click="addTag('nickName')" ref="nickName">昵称</a-tag>
                <a-tag v-if="formData.scene == 'WELCOME'" @click="addTag('email')" ref="email">邮箱</a-tag>
                <a-tag v-if="formData.scene == 'WELCOME'" @click="addTag('password')" ref="password">密码</a-tag>
                <span class="triangle_border_right" ref="start" @click="addTag('start')">
                  <span :style="'border-left-color:' + fontColor">startIcon</span>
                </span>
                <span class="end-icon" :style="'background-color:' + fontColor" ref="end" @click="addTag('end')">
                  <span>endIcon</span>
                </span>
                <!-- <a-tag @click="addTag('ip-tag')" ref="ip-tag">IP</a-tag> -->
              </div>
              <j-editor
                ref="editor"
                :style="confirmLoading ? 'opacity: 0;' : 'opacity: 1;'"
                @onClick="onEditorClick"
                v-model="templateEditorContent"
              ></j-editor>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { httpAction } from '@/api/manage'
import pick from 'lodash.pick'
import { duplicateCheck } from '@/api/api'
import JEditor from '@/components/jeecg/JEditor'

export default {
  name: 'SysMessageTemplateModal',
  components: {
    JEditor
  },
  props: {
    sceneOptions: {
      type: Array,
      default: () => []
    },
    sendTypeOptions: {
      type: Array,
      default: () => []
    },
    priorityMap: {
      type: Map,
      default: () => new Map()
    }
  },
  data() {
    return {
      title: '操作',
      visible: false,
      disable: true,
      templateName: '',
      formData: {
        scene: null,
        templateType: '2',
        sendTypes: [],
        priority: 'MIDDLE',
        templateName: '',
        templateContent: ''
      },
      tagTypeList: [
        'nickName',
        'email',
        'password',
        'productName',
        'chapterOrder',
        'taskName',
        'limitDate',
        'deadline',
        'chapterName',
        'member',
        'ratingDate',
        'department',
        'position',
        'delayReason',
        'rejectStage',
        'start',
        'end'
      ],
      checkedList: [],
      marks: {
        0: '低',
        50: '中',
        100: '高'
      },
      plainOptions: [
        {
          label: '邮件',
          value: 'EMAIL'
        },
        {
          label: '系统通知',
          value: 'SYSTEM'
        },
        {
          label: '微信公众号',
          value: 'WECHAT'
        }
      ],
      model: {},
      options: [
        { label: '低', value: 'LOW' },
        { label: '中', value: 'MIDDLE' },
        { label: '高', value: 'HIGH' }
      ],
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 }
      },
      confirmLoading: false,
      validatorRules: {
        templateCode: {
          rules: [{ required: true, message: '请输入模板CODE!' }, { validator: this.validateTemplateCode }]
        },
        templateName: { rules: [{ required: true, message: '请输入模板标题!' }] },
        templateContent: { rules: [] },
        templateType: { rules: [{ required: true, message: '请输入模板类型!' }] }
      },
      url: {
        add: '/sys/message/sysMessageTemplate/add',
        edit: '/sys/message/sysMessageTemplate/edit'
      },
      useEditor: false,
      templateEditorContent: '',
      templateEditorContentText: '',
      color: '',
      templateNameText: '',
      textIndexArr: [],
      textArr: [],
      titleSelection: null,
      selection: null,
      fontColor: ''
    }
  },
  created() {},
  mounted() {
    this.fontColor = this.$store.state.app.color
    setTimeout(() => {
      for (let css of document.styleSheets) {
        for (let rule of css.cssRules) {
          if (rule.cssText?.includes('.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected')) {
            this.color = rule.style.backgroundColor
          }
        }
      }
    }, 50)
  },
  watch: {
    templateEditorContent: {
      handler(o, n) {
        setTimeout(() => {
          let childNodes = document
            .getElementsByClassName('tox-edit-area__iframe')[1]
            ?.contentWindow.document.getElementsByClassName('ant-tag')
          childNodes &&
            Array.from(childNodes).forEach(item => {
              if (
                Array.from(document.getElementsByClassName('ant-tag')).every(tag => {
                  return tag.innerText != item.innerText
                })
              ) {
                item.parentNode.removeChild(item)
              }
            })
          let childNodesStart = document
            .getElementsByClassName('tox-edit-area__iframe')[1]
            ?.contentWindow.document.getElementsByClassName('triangle_border_right')
          childNodesStart &&
            Array.from(childNodesStart).forEach(item => {
              if (
                Array.from(document.getElementsByClassName('triangle_border_right')).every(tag => {
                  return tag.innerText != item.innerText
                })
              ) {
                item.parentNode.removeChild(item)
              }
            })
          let childNodesEnd = document
            .getElementsByClassName('tox-edit-area__iframe')[1]
            ?.contentWindow.document.getElementsByClassName('end-icon')
          childNodesEnd &&
            Array.from(childNodesEnd).forEach(item => {
              if (
                Array.from(document.getElementsByClassName('end-icon')).every(tag => {
                  return tag.innerText != item.innerText
                })
              ) {
                item.parentNode.removeChild(item)
              }
            })
        }, 0)
      },
      deep: true
    },
    templateName: {
      handler(o, n) {
        setTimeout(() => {
          let childNodes = document
            .getElementsByClassName('tox-edit-area__iframe')[0]
            ?.contentWindow.document.getElementsByClassName('ant-tag')
          childNodes &&
            Array.from(childNodes).forEach(item => {
              if (
                Array.from(document.getElementsByClassName('ant-tag')).every(tag => {
                  return tag.innerText != item.innerText
                })
              ) {
                item.parentNode.removeChild(item)
              }
            })
        }, 0)
      },
      deep: true
    }
  },
  methods: {
    onTitleClick(e, tinymce) {
      console.log({ e, tinymce })
      this.titleSelection = tinymce.activeEditor.selection
    },
    onEditorClick(e, tinymce) {
      console.log({ e, tinymce })
      this.selection = tinymce.activeEditor.selection
      // 获取selection对象
      /* const selection = tinymce.activeEditor.selection.getSel();
      console.dir(selection.focusNode);
      console.log({ 'selection.focusOffset': selection.focusOffset });
 */
    },
    selectChange(e) {
      switch (e) {
        case 'WELCOME':
          this.templateName = '【新账号注册】'
          break
        case 'TASK_READY':
          this.templateName = '【新任务】'
          break
        case 'TASK_START':
          this.templateName = '【可开始】'
          break
        case 'TASK_BEEN_KPI':
          this.templateName = '【已审核】'
          break
        case 'TASK_WARNING':
          this.templateName = '【预警】'
          break
        case 'PRODUCTION_CHAPTER_NOTE':
          this.templateName = '【备注】'
          break
        case 'TASK_SCHEDULE_CHANGE_APPLY':
          this.templateName = '【可安排数量】'
          break
        case 'QUESTION_ANSWER_REPLY':
          this.templateName = '【帮助与反馈】'
          break
        case 'TASK_REARRANGE':
          this.templateName = '【不能完成】'
          break
        case 'TASK_DELAY':
          this.templateName = '【拖稿】'
          break
        case 'TASK_REJECT':
          this.templateName = '【驳回】'
          break
        case 'TASK_DELAY_FINISH':
          this.templateName = '【拖稿完成】'
          break
        case 'TASK_TO_KPI':
          this.templateName = '【未审核】'
          break
        case 'GRADE':
          this.templateName = '【评级】'
          break
        case 'SETTLE_BOSS_CONFIRMED':
        case 'SETTLE_BOSS_PAYED':
          this.templateName = '【结算】'
          break
      }
      this.templateNameText = this.templateName
      this.templateEditorContent = ''
      this.templateEditorContentText = ''
    },
    add() {
      this.disable = false
      this.edit({})
    },
    addTag(name) {
      let childNodes = document
        .getElementsByClassName('tox-edit-area__iframe')[1]
        .contentWindow.document.getElementsByClassName('mce-content-body')[0].childNodes
      let checkText = document
        .getElementsByClassName('tox-edit-area__iframe')[1]
        .contentWindow.document.getElementsByClassName('mce-content-body')[0].innerText
      let tag = null
      console.log(this.selection.getNode().innerText)
      if (name != 'start' && name != 'end') {
        tag = this.$refs[name].$el.cloneNode(true)
        this.tagStyle(tag)
      } else if (name == 'start') {
        if (
          document
            .getElementsByClassName('tox-edit-area__iframe')[1]
            .contentWindow.document.getElementsByClassName('mce-content-body')[0]
            .getElementsByClassName('triangle_border_right').length
        ) {
          return
        } else {
          tag = this.$refs[name].cloneNode(true)
          this.startStyle(tag)
        }
      } else if (name == 'end') {
        if (
          document
            .getElementsByClassName('tox-edit-area__iframe')[1]
            .contentWindow.document.getElementsByClassName('mce-content-body')[0]
            .getElementsByClassName('end-icon').length
        ) {
          return
        } else {
          tag = this.$refs[name].cloneNode(true)
          this.endStyle(tag)
        }
      }
      if (name == 'start') {
        if (this.selection) {
          if (checkText.split(this.selection.getNode().innerText)[0].indexOf('endIcon') > -1) {
            this.$message.error('开始标签不能位于结束标签之后')
            return
          }
        } else {
          if (checkText.indexOf('endIcon') > -1) {
            this.$message.error('开始标签不能位于结束标签之后')
            return
          }
        }
      } else if (name == 'end') {
        if (checkText.indexOf('startIcon') == -1) {
          this.$message.error('请先插入开始标签')
          return
        }
        if (this.selection) {
          if (checkText.indexOf('startIcon') >= -1) {
            if (
              checkText.split(this.selection.getNode().innerText).length > 1 &&
              checkText.split(this.selection.getNode().innerText)[1].indexOf('startIcon') > -1
            ) {
              this.$message.error('结束标签不能位于开始标签之前')
              return
            }
          }
        }
      }
      let span = document.createElement('span')
      span.innerHTML = '&nbsp;'
      if (this.selection) {
        this.selection.setNode(tag)
        this.selection.setNode(span)
      } else {
        childNodes[childNodes.length - 1].append(tag)
        childNodes[childNodes.length - 1].append(span)
      }
    },
    addTagName(name) {
      let childNodes = document
        .getElementsByClassName('tox-edit-area__iframe')[0]
        .contentWindow.document.getElementsByClassName('mce-content-body')[0].childNodes
      let tag = null
      if (name != 'start') {
        tag = this.$refs[name].$el.cloneNode(true)
        this.tagStyle(tag)
      }
      let span = document.createElement('span')
      span.innerHTML = '&nbsp;'
      if (this.titleSelection) {
        this.titleSelection.setNode(tag)
        this.titleSelection.setNode(span)
      } else {
        childNodes[childNodes.length - 1].append(tag)
        childNodes[childNodes.length - 1].append(span)
      }
    },
    addTagNameEdit(item) {
      let childNodes = document
        .getElementsByClassName('tox-edit-area__iframe')[0]
        .contentWindow.document.getElementsByClassName('mce-content-body')[0].childNodes
      if (item.textType == 'tag') {
        let tag = this.$refs[item.text].$el.cloneNode(true)
        this.tagStyle(tag)
        childNodes[childNodes.length - 1].append(tag)
      }
      if (item.textType == 'span') {
        let span = document.createElement('span')
        span.innerHTML = item.text
        childNodes[childNodes.length - 1].append(span)
      }
      let span = document.createElement('span')
      span.innerHTML = '&nbsp;'
      childNodes[childNodes.length - 1].append(span)
    },
    addTagEdit(item) {
      let arr = []
      if (item.textType == 'tag') {
        console.log(item.text,123)
        let tag = this.$refs[item.text].$el.cloneNode(true)
        this.tagStyle(tag)
        arr.push(tag)
      }
      if (item.textType == 'start') {
        let tag = this.$refs['start'].cloneNode(true)
        this.startStyle(tag)
        arr.push(tag)
      }
      if (item.textType == 'end') {
        let tag = this.$refs['end'].cloneNode(true)
        this.endStyle(tag)
        arr.push(tag)
      }
      if (item.textType == 'span') {
        let span = document.createElement('span')
        span.innerHTML = item.text
        arr.push(span)
      }
      let span = document.createElement('span')
      span.innerHTML = '&nbsp;'
      arr.push(span)
      return arr
    },
    addTagEditText(text) {
      let p = document.createElement('p')
      let childNodes = document
        .getElementsByClassName('tox-edit-area__iframe')[1]
        .contentWindow.document.getElementsByClassName('mce-content-body')[0].childNodes
      p.innerHTML = text
      childNodes[childNodes.length - 1].append(p)
    },
    edit(record) {
      this.confirmLoading = true
      this.selection = null
      this.titleSelection = null
      this.templateName = record.templateName
      let arr = []
      this.tagTypeList.forEach(item => {
        if (this.templateName?.indexOf(item) != '-1') {
          arr.push({ type: item, index: this.templateName?.indexOf(item) })
        }
      })
      arr.sort((a, b) => {
        return a.index - b.index
      })
      this.model = Object.assign({}, record)
      this.formData = Object.assign(this.formData, this.model)
      this.useEditor = record.templateType == 2 || record.templateType == 4
      this.templateEditorContent = record.templateContent
      let arrContent = []
      this.tagTypeList.forEach(item => {
        if (this.templateEditorContent?.indexOf(item) != '-1' && item != 'start' && item != 'end') {
          arrContent.push({ type: item, index: this.templateEditorContent?.indexOf(item) })
        } else if (this.templateEditorContent?.indexOf('<#list list as item>') != '-1' && item == 'start') {
          arrContent.push({ type: item, index: this.templateEditorContent?.indexOf('<#list list as item>') })
        } else if (this.templateEditorContent?.indexOf('</#list>') != '-1' && item == 'end') {
          arrContent.push({ type: item, index: this.templateEditorContent?.indexOf('</#list>') })
        }
      })
      arrContent.sort((a, b) => {
        return a.index - b.index
      })
      this.visible = true
      /* this.$nextTick(() => {
        if (this.useEditor) {
          this.form.setFieldsValue(pick(this.model, 'templateCode', 'templateName', 'templateTestJson', 'templateType'))
        } else {
          this.form.setFieldsValue(
            pick(this.model, 'templateCode', 'templateContent', 'templateName', 'templateTestJson', 'templateType')
          )
        }
      }) */
      setTimeout(() => {
        document.getElementsByClassName('tox-edit-area__iframe')[1].contentWindow.document.body.style.paddingTop =
          '45px'
        document.getElementsByClassName('tox-edit-area__iframe')[0].contentWindow.document.body.style.paddingTop =
          '45px'
        this.confirmLoading = false
      }, 500)
      setTimeout(() => {
        this.textIndexArr = []
        this.textArr = []
        let childNodes = document
          .getElementsByClassName('tox-edit-area__iframe')[0]
          .contentWindow.document.getElementsByClassName('mce-content-body')[0].childNodes
        arr.forEach(item => {
          this.textIndexArr.push({
            start: childNodes[0].innerText.indexOf('${item.' + item.type + '}'),
            end: childNodes[0].innerText.indexOf('${item.' + item.type + '}') + item.type.length + 7
          })
          this.textArr.push({
            start: childNodes[0].innerText.indexOf('${item.' + item.type + '}'),
            end: childNodes[0].innerText.indexOf('${item.' + item.type + '}') + item.type.length + 7,
            textType: 'tag',
            text: item.type
          })
        })
        if (this.textIndexArr.length) {
          this.textIndexArr.forEach((item, index) => {
            if (index == 0) {
              this.textArr.push({
                text: childNodes[0].innerText.slice(0, item.start),
                textType: 'span',
                start: 0,
                end: item.start - 1
              })
              if (this.textIndexArr[index + 1]) {
                this.textArr.push({
                  text: childNodes[0].innerText.slice(item.end + 1, this.textIndexArr[index + 1].start),
                  textType: 'span',
                  start: item.end + 1,
                  end: this.textIndexArr[index + 1].start
                })
              }
            } else {
              if (this.textIndexArr[index + 1]) {
                this.textArr.push({
                  text: childNodes[0].innerText.slice(item.end + 1, this.textIndexArr[index + 1].start),
                  textType: 'span',
                  start: item.end + 1,
                  end: this.textIndexArr[index + 1].start
                })
              }
            }
            if (index == this.textIndexArr.length - 1) {
              if (childNodes[0].innerText.slice(item.end + 1)) {
                this.textArr.push({
                  text: childNodes[0].innerText.slice(item.end + 1),
                  textType: 'span',
                  start: item.end + 1,
                  end: childNodes[0].innerText.length - 1
                })
              }
            }
          })
          this.textArr = this.textArr.sort((a, b) => {
            return a.start - b.start
          })
          childNodes[0].innerText = ''
          this.textArr.forEach(item => {
            this.addTagNameEdit(item)
          })
        }
      }, 500)
      setTimeout(() => {
        let childNodes = document
          .getElementsByClassName('tox-edit-area__iframe')[1]
          .contentWindow.document.getElementsByClassName('mce-content-body')[0].childNodes
        childNodes.forEach(item => {
          item.innerHTML = ''
        })
        this.templateEditorContent.split('<p>').forEach(p => {
          p.split('</p>').forEach(i => {
            if (i) {
              this.textIndexArr = []
              this.textArr = []
              arrContent.forEach(item => {
                if (item.type != 'start' && item.type != 'end') {
                  if (i.indexOf('${item.' + item.type + '}') != -1) {
                    this.textIndexArr.push({
                      start: i.indexOf('${item.' + item.type + '}'),
                      end: i.indexOf('${item.' + item.type + '}') + item.type.length + 7
                    })
                    this.textArr.push({
                      start: i.indexOf('${item.' + item.type + '}'),
                      end: i.indexOf('${item.' + item.type + '}') + item.type.length + 7,
                      textType: 'tag',
                      text: item.type
                    })
                  }
                } else if (item.type == 'start') {
                  if (i.indexOf('<#list list as item>') != -1) {
                    let text = '<#list list as item>'
                    this.textIndexArr.push({
                      start: i.indexOf(text),
                      end: i.indexOf(text) + text.length - 1
                    })
                    this.textArr.push({
                      start: i.indexOf(text),
                      end: i.indexOf(text) + text.length - 1,
                      textType: 'start',
                      text: item.type
                    })
                  }
                } else if (item.type == 'end') {
                  if (i.indexOf('</#list>') != -1) {
                    let text = '</#list>'
                    this.textIndexArr.push({
                      start: i.indexOf(text),
                      end: i.indexOf(text) + text.length - 1
                    })
                    this.textArr.push({
                      start: i.indexOf(text),
                      end: i.indexOf(text) + text.length - 1,
                      textType: 'end',
                      text: item.type
                    })
                  }
                }
              })
              this.textIndexArr.forEach((item, index) => {
                if (index == 0) {
                  this.textArr.push({
                    text: i.slice(0, item.start),
                    textType: 'span',
                    start: 0,
                    end: item.start - 1
                  })
                  if (this.textIndexArr[index + 1]) {
                    this.textArr.push({
                      text: i.slice(item.end + 1, this.textIndexArr[index + 1].start),
                      textType: 'span',
                      start: item.end + 1,
                      end: this.textIndexArr[index + 1].start
                    })
                  }
                } else {
                  if (this.textIndexArr[index + 1]) {
                    this.textArr.push({
                      text: i.slice(item.end + 1, this.textIndexArr[index + 1].start),
                      textType: 'span',
                      start: item.end + 1,
                      end: this.textIndexArr[index + 1].start
                    })
                  }
                }
                if (index == this.textIndexArr.length - 1) {
                  if (i.slice(item.end + 1)) {
                    this.textArr.push({
                      text: i.slice(item.end + 1),
                      textType: 'span',
                      start: item.end + 1,
                      end: i.length - 1
                    })
                  }
                }
              })
              this.textArr = this.textArr.sort((a, b) => {
                return a.start - b.start
              })
              if (this.textArr) {
                let p = document.createElement('p')
                let childNodes = document
                  .getElementsByClassName('tox-edit-area__iframe')[1]
                  .contentWindow.document.getElementsByClassName('mce-content-body')[0].childNodes
                this.textArr.forEach(item => {
                  this.addTagEdit(item).forEach(dom => {
                    p.append(dom)
                  })
                })
                childNodes[childNodes.length - 1].append(p)
              }
              if (!this.textIndexArr.length) {
                this.addTagEditText(i)
              }
            }
          })
        })
      }, 500)
    },
    close() {
      this.$emit('close')
      this.visible = false
      this.disable = true
      this.formData = {
        scene: null,
        templateType: '2',
        sendTypes: [],
        priority: 'MIDDLE',
        templateName: '',
        templateContent: ''
      }
      /* this.$options.data() */
    },
    handleOk() {
      this.model.templateType = this.templateType
      this.model = Object.assign(this.model, this.formData)
      this.templateNameText = ''
      this.templateEditorContentText = ''
      Array.from(
        document.getElementsByClassName('tox-edit-area__iframe')[0].contentWindow.document.getElementsByTagName('p')
      ).forEach(p => {
        p.childNodes.forEach(item => {
          if (item.innerText && item.className == 'ant-tag') {
            switch (item.innerText) {
              case '昵称':
                this.templateNameText += '${item.nickName}'
                break
              case '邮箱':
                this.templateNameText += '${item.email}'
                break
              case '密码':
                this.templateNameText += '${item.password}'
                break
              case '作品名称':
                this.templateNameText += '${item.productName}'
                break
              case '章节序号':
                this.templateNameText += '${item.chapterOrder}'
                break
              case '章节名称':
                this.templateNameText += '${item.chapterName}'
                break
              case '阶段名称':
                this.templateNameText += '${item.taskName}'
                break
              case '截止日期':
                this.templateNameText += '${item.limitDate}'
                break
              case '截止时间':
                this.templateNameText += '${item.deadline}'
                break
              case '人员':
                this.templateNameText += '${item.member}'
                break
              case '日期（年-月）':
                this.templateNameText += '${item.ratingDate}'
                break
              case '部门':
                this.templateNameText += '${item.department}'
                break
              case '职位':
                this.templateNameText += '${item.position}'
                break
              case '原因':
                this.templateNameText += '${item.delayReason}'
                break
              case '驳回阶段名称':
                this.templateNameText += '${item.rejectStage}'
                break
            }
          } else if (!item.innerText) {
            this.templateNameText += item.data == undefined ? '' : item.data
          } else if (item.innerText && item.className != 'ant-tag' && !item.getElementsByClassName('ant-tag').length) {
            this.templateNameText += item.innerText.replace(/\s*/g, '')
          } else if (item.innerText && item.className != 'ant-tag' && item.getElementsByClassName('ant-tag').length) {
            if (item.innerText) {
              let text = item.innerText
              text = text.replaceAll('昵称', '${item.nickName}')
              text = text.replaceAll('邮箱', '${item.email}')
              text = text.replaceAll('密码', '${item.password}')
              text = text.replaceAll('作品名称', '${item.productName}')
              text = text.replaceAll('章节序号', '${item.chapterOrder}')
              text = text.replaceAll('章节名称', '${item.chapterName}')
              text = text.replaceAll('阶段名称', '${item.taskName}')
              text = text.replaceAll('截止日期', '${item.limitDate}')
              text = text.replaceAll('截止时间', '${item.deadline}')
              text = text.replaceAll('人员', '${item.member}')
              text = text.replaceAll('日期（年-月）', '${item.ratingDate}')
              text = text.replaceAll('部门', '${item.department}')
              text = text.replaceAll('职位', '${item.position}')
              text = text.replaceAll('原因', '${item.delayReason}')
              text = text.replaceAll('驳回阶段名称', '${item.rejectStage}')
              this.templateNameText += text
            }
          }
        })
      })
      Array.from(
        document.getElementsByClassName('tox-edit-area__iframe')[1].contentWindow.document.getElementsByTagName('p')
      ).forEach((p, index) => {
        let templateEditorContentText = ''
        if (p.innerHTML && !p.getElementsByTagName('p').length) {
          p.childNodes.forEach(item => {
            if (item.innerText && item.className == 'ant-tag') {
              switch (item.innerText) {
                case '昵称':
                  templateEditorContentText += '${item.nickName}'
                  break
                case '邮箱':
                  templateEditorContentText += '${item.email}'
                  break
                case '密码':
                  templateEditorContentText += '${item.password}'
                  break
                case '作品名称':
                  templateEditorContentText += '${item.productName}'
                  break
                case '章节序号':
                  templateEditorContentText += '${item.chapterOrder}'
                  break
                case '章节名称':
                  templateEditorContentText += '${item.chapterName}'
                  break
                case '阶段名称':
                  templateEditorContentText += '${item.taskName}'
                  break
                case '截止日期':
                  templateEditorContentText += '${item.limitDate}'
                  break
                case '截止时间':
                  templateEditorContentText += '${item.deadline}'
                  break
                case '人员':
                  templateEditorContentText += '${item.member}'
                  break
                case '日期（年-月）':
                  templateEditorContentText += '${item.ratingDate}'
                  break
                case '部门':
                  templateEditorContentText += '${item.department}'
                  break
                case '职位':
                  templateEditorContentText += '${item.position}'
                  break
                case '原因':
                  templateEditorContentText += '${item.delayReason}'
                  break
                case '驳回阶段名称':
                  templateEditorContentText += '${item.rejectStage}'
                  break
              }
            } else if (item.innerText && item.className == 'triangle_border_right') {
              templateEditorContentText += '<#list list as item>'
            } else if (item.innerText && item.className == 'end-icon') {
              templateEditorContentText += '</#list>'
            } else if (!item.innerText) {
              templateEditorContentText += item.data == undefined ? '' : item.data
            } else if (
              item.innerText &&
              item.className != 'ant-tag' &&
              !item.getElementsByClassName('ant-tag').length &&
              !item.getElementsByClassName('triangle_border_right').length &&
              !item.getElementsByClassName('end-icon').length
            ) {
              templateEditorContentText += item.innerText.replaceAll(/\s*/g, '')
            } else if (item.innerText && item.className != 'ant-tag' && item.getElementsByClassName('ant-tag').length) {
              Array.from(item.getElementsByClassName('ant-tag')).forEach(i => {
                if (i.innerText && i.className == 'ant-tag') {
                  switch (i.innerText) {
                    case '昵称':
                      templateEditorContentText += '${item.nickName}'
                      break
                    case '邮箱':
                      templateEditorContentText += '${item.email}'
                      break
                    case '密码':
                      templateEditorContentText += '${item.password}'
                      break
                    case '作品名称':
                      templateEditorContentText += '${item.productName}'
                      break
                    case '章节序号':
                      templateEditorContentText += '${item.chapterOrder}'
                      break
                    case '章节名称':
                      templateEditorContentText += '${item.chapterName}'
                      break
                    case '阶段名称':
                      templateEditorContentText += '${item.taskName}'
                      break
                    case '截止日期':
                      templateEditorContentText += '${item.limitDate}'
                      break
                    case '截止时间':
                      templateEditorContentText += '${item.deadline}'
                      break
                    case '人员':
                      templateEditorContentText += '${item.member}'
                      break
                    case '日期（年-月）':
                      templateEditorContentText += '${item.ratingDate}'
                      break
                    case '部门':
                      templateEditorContentText += '${item.department}'
                      break
                    case '职位':
                      templateEditorContentText += '${item.position}'
                      break
                    case '原因':
                      templateEditorContentText += '${item.delayReason}'
                      break
                    case '驳回阶段名称':
                      templateEditorContentText += '${item.rejectStage}'
                      break
                  }
                }
              })
            } else if (item.innerText && item.getElementsByClassName('triangle_border_right').length) {
              templateEditorContentText += item.innerText
                .replaceAll(/\s*/g, '')
                .replaceAll('startIcon', '<#list list as item>')
            } else if (item.innerText && item.getElementsByClassName('end-icon').length) {
              templateEditorContentText += item.innerText.replaceAll(/\s*/g, '').replaceAll('endIcon', '</#list>')
            }
          })

          this.templateEditorContentText += '<p>' + templateEditorContentText + '</p>'
        }
      })
      if (
        this.templateEditorContentText.indexOf('</#list>') > -1 &&
        this.templateEditorContentText.indexOf('</#list>') <
          this.templateEditorContentText.indexOf('<#list list as item>')
      ) {
        this.$message.error('结束标签不能位于开始标签之前')
        return
      }
      if (
        this.templateEditorContentText.indexOf('</#list>') == -1 &&
        this.templateEditorContentText.indexOf('<#list list as item>') > -1
      ) {
        this.$message.error('请插入结束标签')
        return
      }
      console.log(this.templateEditorContentText)
      const that = this
      // 触发表单验证
      that.confirmLoading = true
      let httpurl = ''
      let method = ''
      if (!this.model.id) {
        httpurl += this.url.add
        method = 'post'
      } else {
        httpurl += this.url.edit
        method = 'put'
      }
      this.formData.templateName = this.templateNameText.replaceAll('undefined', '').replaceAll('undefined', '')
      /* this.formData.templateName=this.templateName.replaceAll('undefined', '') */
      let formData = this.formData
      let typeArr = []
      this.sendTypeOptions.forEach(item => {
        this.formData.sendTypes.forEach(type => {
          if (type == item.value) {
            typeArr.push(item.value)
          }
        })
      })
      this.formData.sendTypes = typeArr
      this.templateEditorContent = this.templateEditorContentText
        .replaceAll('undefined', '')
        .replaceAll('<p>&nbsp;</p>', '')
      formData.templateContent = this.templateEditorContent.replaceAll('undefined', '').replaceAll('<p>&nbsp;</p>', '')
      httpAction(httpurl, formData, method)
        .then(res => {
          if (res.success) {
            that.$message.success(res.message)
            that.$emit('ok')
          } else {
            that.$message.warning(res.message)
          }
        })
        .finally(() => {
          that.confirmLoading = false
          that.close()
        })
    },
    validateTemplateCode(rule, value, callback) {
      var params = {
        tableName: 'sys_sms_template',
        fieldName: 'template_code',
        fieldVal: value,
        dataId: this.model.id
      }
      duplicateCheck(params).then(res => {
        if (res.success) {
          callback()
        } else {
          callback(res.message)
        }
      })
    },
    handleCancel() {
      this.close()
    },
    handleChangeTemplateType(value) {
      //如果是邮件类型那么则改变模板内容是富文本编辑器
      this.useEditor = value == 2 || value == 4
    },
    tagStyle(tag) {
      tag.style.boxSizing = 'border-box'
      tag.style.margin = '0'
      tag.style.padding = '0'
      tag.style.color = this.$store.state.app.color
      tag.style.fontVariant = 'tabular-nums'
      tag.style.lineHeight = '1.5'
      tag.style.listStyle = 'none'
      tag.style.fontFeatureSettings = 'tnum'
      tag.style.display = 'inline-block'
      tag.style.height = 'auto'
      tag.style.marginRgiht = '8px'
      tag.style.padding = '0 7px'
      tag.style.fontSize = '15px'
      tag.style.lineHeight = '30px'
      tag.style.whiteSpace = 'nowrap'
      tag.style.background = this.color
      tag.style.border = '1px solid #d9d9d9'
      tag.style.borderColor = this.color
      tag.style.borderRadius = '4px'
      tag.style.cursor = 'default'
      tag.style.opacity = '1'
      tag.style.transition = 'all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86)'
    },
    startStyle(tag) {
      tag.style.position = 'relative'
      tag.style.width = '20px'
      tag.style.height = '10px'
      tag.style.display = 'inline-block'
      tag.style.top = '-9px'
      tag.childNodes[0].style.display = 'block'
      tag.childNodes[0].style.width = '0px'
      tag.childNodes[0].style.height = '0'
      tag.childNodes[0].style.borderWidth = '8px 0 8px 12px'
      tag.childNodes[0].style.borderStyle = 'solid'
      tag.childNodes[0].style.borderColor = 'transparent transparent transparent ' + this.fontColor
      tag.childNodes[0].style.position = 'absolute'
      tag.childNodes[0].style.color = 'rgba(0,0,0,0)'
      tag.childNodes[0].style.left = '0'
      tag.childNodes[0].style.top = '5px'
    },
    endStyle(tag) {
      tag.style.position = 'relative'
      tag.style.width = '15px'
      tag.style.height = '15px'
      tag.style.display = 'inline-block'
      tag.style.top = '2px'
      tag.style.borderRadius = '3px'
      tag.style.color = 'rgba(0, 0, 0, 0)'
      tag.style.overflow = 'hidden'
    }
  }
}
</script>

<style lang="less" scoped>
.end-icon {
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 3px;
  position: relative;
  color: rgba(0, 0, 0, 0);
  left: 18px;
  top: 5px;
  overflow: hidden;
  cursor: pointer;
}
.triangle_border_right {
  cursor: pointer;
  position: relative;
  top: -3px;
}
.triangle_border_right span {
  display: block;
  width: 0;
  height: 0;
  border-width: 8px 0 8px 12px;
  border-style: solid;
  color: rgba(0, 0, 0, 0);
  border-color: transparent transparent transparent #fff;
  position: absolute;
}
.tag-list {
  position: absolute;
  top: 9px;
  left: 8px;
  z-index: 99999;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 98%;
  height: 31px;
  background-color: #fff;
  .ant-tag {
    margin-top: 8px;
  }
}
.ant-checkbox-group {
  display: flex;
  height: 38px;
  align-items: center;
}
/deep/ .tox-editor-header {
  display: none;
}
/deep/ .mce-content-body {
  padding-top: 40px !important;
}
/deep/ .template-name .tox {
  height: 130px !important;
}
/deep/ .template-editor .tox {
  height: 300px !important;
}
/deep/ .tox-statusbar {
  display: none !important;
}
/deep/ .tinymce-editor {
  padding-top: 7px !important;
}
</style>
